
import { Options, Vue } from 'vue-class-component';
// import { Watch } from 'vue-property-decorator'
import MetaLogo from "@/components/MetaLogo.vue";
// import {RouteRecordNormalized} from 'vue-router';
import { getScrollParentElement } from "@/utils";
import debounce from "lodash.debounce";

@Options<Navigator>({
  components: {
    MetaLogo
  },

})
export default class Navigator extends Vue {
  // @Watch('$route',{deep:true})
  // onRouteChanged(newRoute:RouteRecordNormalized){
  //   console.log('newRoute',newRoute);
  // }
  judgeActivePage(pageName:string){
    if(pageName === 'Production'){
      return this.$route.path.search('production') > -1;
    }
    return this.$route.name === pageName;
  }

  proxyRedirect(name:string){
    this.$router.push({name:name});
  }

  async makeNavigatorReactive(){
    let scrollParent:HTMLElement;
    scrollParent = getScrollParentElement(this.$el);

    if(!scrollParent) return;
    const scrollClientHeight = scrollParent.clientHeight;
    let enableInvisible = false;
    const debounceFun = debounce(() => {
      enableInvisible = true;
    },200);
    const tryToHide = () => {
      if(!enableInvisible) return;
      this.$el.classList.add('invisible');
    }
    const show = () => {
      this.$el.classList.remove('invisible');
      enableInvisible = false;
      debounceFun();
    }

    let prevScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = scrollParent.scrollTop;
      const isUp = prevScrollTop > currentScrollTop;
      prevScrollTop = currentScrollTop;
      // this.$el.classList[(scrollParent.scrollTop > screen.height / 2) ? 'add' : 'remove']('mask');
      this.$el.classList[(scrollParent.scrollTop > 100 ) ? 'add' : 'remove']('mask');

      if(isUp){
        show();
      }else{
        if(currentScrollTop > 200){
          tryToHide();
        }else{
          show();
        }
      }
    }
    scrollParent.addEventListener('wheel',() => {
      handleScroll();
    });
    scrollParent.addEventListener('touchmove',() => {
      handleScroll();
    })

  }

  mounted(){

    const interval = setInterval(() => {
      if('MetasoSearch' in window){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // eslint-disable-next-line no-undef
        MetasoSearch.mount({
          apiKey: 'mk-D0B359A4A804213C952EFBFC1F220A1C',
          topicId: "8547516269457154048",
          colorMode: 'dark',
          root: '#metaso-search-widget-root'
        });

        clearInterval(interval);
      }
    }, 1000);

    this.makeNavigatorReactive();
  }
}
